.AdvanceDashboard .AdvanceDashboardTabs .tab-content {
  background: #f8f9fa;
}

.main-advancedashboard {
  min-width: 78%;
}

/* .AdvanceDashboard {
  height: 100vh;
  overflow-y: auto;
  background: rgb(242 242 242);
} */

.AdvanceDashboard .viewMOre_btn {
  background-color: transparent;
  border-radius: 29px;
  color: #40189D;
  padding: 12px;
}

.AdvanceDashboard .settingCard {
  height: 100vh;
  margin: 5% 0% 0% 8%;
  border: 0 !important;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 0 4px #000;
}

.AdvanceDashboard .Setting_list li {
  margin-bottom: 13px;
  font-size: 15px;
  padding: 7px 0px 7px 15px;
}

.AdvanceDashboard .settingInputGrp {
  padding: 5px;
}

.AdvanceDashboard .Setting_list li:hover {
  background-color: #005c97;
  color: #fff;
}

.AdvanceDashboard .Setting_list {
  list-style-type: none !important;
  padding-left: 0;
}

.AdvanceDashboard .badhboard-header {
  display: flex;
  align-items: center;
}

.AdvanceDashboard .jobCards .card {
  margin-left: 17px;
}

.AdvanceDashboard .badhboard-header .setting {
  position: absolute;
  right: 20px;
  display: flex;
}

.AdvanceDashboard .badhboard-header .setting .calender {
  margin-right: 50px;
}

.AdvanceDashboard .recommendImg {
  display: flex;
  justify-content: space-between;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav {
  border-bottom: 1px solid #80808054;
  border-top: 1px solid #80808054;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav .nav-link {
  border: none;
  color: slategray;
  font-size: 15px;
}

.AdvanceDashboard .AdvanceDashboardTabs .nav .nav-link.active {
  border: none;
  border-bottom-style: outset;
  border-color: #0c81ed;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent {
  padding: 1% 1% 0% 2%;
  overflow-y: auto;
  background: rgb(242 242 242);
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box {
  padding: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.625rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
}



.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .number-holder {
  display: flex;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .number-holder .number {
  margin-bottom: 0px;
  margin-right: 20px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .number-holder .number p {
  font-size: 12px;
  margin-bottom: 0px;
}

.AdvanceDashboard .profile-image img {
  margin: 18px;
  width: 128px;
  border: 13px solid #40189D;
  padding: 3px;
}

.AdvanceDashboard .media-body p {
  font-size: 14px;
}

.AdvanceDashboard .media-body span {
  font-size: 14px;
}

.AdvanceDashboard .profileCard {
  height: auto !important;
}

.AdvanceDashboard .featuredCard {
  height: auto !important;
  margin: 0px 0px 0px 19px;
}

.AdvanceDashboard .CircularProgressbar {
  width: 76% !important;
  vertical-align: middle;
}

.AdvanceDashboard .featuredCard .Featured_companyName {
  font-weight: 600;
}

.AdvanceDashboard .featuredCard .Featured_vacancy {
  color: #40189d;
  font-weight: 500;
}

.AdvanceDashboard .featuredCard .card-body {
  padding: 1.875rem !important;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .number-holder .number span {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
}

.AdvanceDashboard .thisMonth_btn {
  border-radius: 56px;
}

.AdvanceDashboard .dropdown-toggle::after {
  display: none;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .text-holder .text {
  font-size: 15px;
  margin-bottom: 10px;
  color: #fff;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .box .users {
  color: #fff;
  font-size: 36px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .featuredCard .card-body {
  display: flex !important;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 0px;
}




.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body {
  /* display: flex; */
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 0px;
  border-bottom: 1px solid #ccc;
}

.AdvanceDashboard .swiper-pagination-bullet {
  background-color: #005c97;
  height: 14px;
  width: 14px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card {
  --bs-card-border-color: none;
  height: 500px;
  box-shadow: 0 0 4px 0 #e8eef3;
  margin-bottom: 38px;
}


.AdvanceDashboard .remote_btn {
  border: 1px solid #ccc;
  border-radius: 27px;
  padding: 12px 15px;
  color: #40189d;
  background-color: #d6d0e3;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-title h4 {
  font-size: 18px;
  font-weight: 600;
}

.AdvanceDashboard .card_nav {
  padding: 7px 36px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do td {
  border-bottom: none;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .text-holder h5 {
  font-size: 13px;
  font-weight: 500;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .text-holder span {
  font-size: 13px;
  color: slategrey;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .img-holder {
  display: flex;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table tr:hover {
  background-color: none;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .date-holder p {
  font-size: 13px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .title-holder p {
  font-size: 13px;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .to-do .title-holder svg {
  color: #f5c308;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .card-body-data .no-data p {
  font-size: 15px;
  color: lightgrey;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table .heading th {
  font-size: 13px;
  font-weight: 500;
  color: slategray;
}

.AdvanceDashboard .AdvanceDashboardTabs .tab-content .AdvanceDashboardTabsContent .card .card-body .table tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: none;
}



@media screen and (min-width: 768px) and (max-width: 991px) {
  .AdvanceDashboard .card_nav {
    padding: 7px 6px;
  }

  .AdvanceDashboard .viewMOre_btn {
    padding: 5px;
    font-size: 13px;
}

  .AdvanceDashboard .remote_btn {
    padding: 5px 8px;
    font-size: 12px;
  }

  .AdvanceDashboard .city_txt {
    font-size: 12px;
  }

  .AdvanceDashboard .graph_txt {
    font-size: 12px;
  }
}










@media screen and (min-width: 2000px) and (max-width: 2560px) {
  /* .AdvanceDashboard {
    height: 93vh;
    overflow-y: auto;

  } */
}