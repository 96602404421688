.header .header-icons {
  display: flex;
  align-items: center;
  background: #07555f;
  color: #FFE;
  padding: 8px;
  border-radius: 5px;
  margin: auto;
  cursor: pointer;
}

.header .header-icons .icon {
  margin-right: 45px;
}

.logoutmodal .logouttxttt {
  font-size: 14px;
}

.logoutmodal .closebtnnnnn {
  background: #07555f;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #363795;
}

.header .search-area .form-control {
  background: #e3e3e3;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 60px 0 0 60px;
  box-shadow: none;
}

.header .header_icons {
  background-color: #fff;
  padding: 9px;
  border-radius: 50%;
  line-height: 1;
}

.header .search-area .input-group-text {
  background: #e3e3e3;
  border-width: 0px;
  border-radius: 60px;
  padding: 3px 9px;
}

.header nav h1 {
  margin: 0% 20px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0px;
}

.header nav {
  background: rgb(242 242 242);
  padding: 20px 50px 20px 0;
  border-top-left-radius: 26px;
}

.header .dropdown-toggle::after {
  display: none !important;
}

.header .dropdown-item {
  padding: 8px 38px;
  font-size: 14px;
  font-weight: 600;
}

.header .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  left: -27px;
  top: 25px;
  padding: 0;
}

.header .logout_content {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

.header .super_admintxt {
  margin-bottom: 0;
  font-size: 12px;
}

.header .header-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .header-text p {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.header .header-text svg {
  font-size: 25px;
  margin-right: 3px;
  margin-left: 15px;
  color: #363795;
}


@media screen and (min-width: 768px) and (max-width:991px) {
  .header nav {

    border-top-left-radius: 18px;
  }
}