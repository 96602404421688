.settingCard {
  height: 100vh;
  margin: 5% 0% 0% 8%;
  border: 0 !important;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 0 4px #000;
  padding: 0px 5px;
}

.settingCard .nav-pills .nav-link {
  color: black;
  font-size: 14px;
}

.settingCard .nav-pills .nav-link.active {
  color: #ffff;
  background: #005c97;
}

.settingCard .settingInputGrp {
  position: sticky;
  top: 0;
  background: #fff;
}