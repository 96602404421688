/* .main-container{
    display:flex;
  }
  .sidebar{
    background: #000;
    color: #fff;
    width: 200px;
    height: 100vh;
    transition: all 0.5s;
    overflow: scroll

  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  .top_section {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 20px 15px;
}
  .logo{
    font-size: 30px;
  }
  .bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
  }
  .link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
  }
  .link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
  }
  .active{
    background: #f8f9fa;
    color: #000;
  }
  .icon, .link_text {
    font-size: 15px;
  }

  .sub-menu {
    font-size: 15px;
} */

/* .sidebar{
  background: #171f29;
  color: #fff;
  width: 200px;
  height: 100vh !important;
  height: auto ;
  transition: all 0.5s;
  overflow: scroll

} */

.sidebar {
  color: #fff;

  height: 100vh;
  transition: all 0.5s;
  overflow-y: auto;
  background: #005c97;
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  background: linear-gradient(to right, #005c97, #363795);
}

::-webkit-scrollbar {
  display: none;
}

.sidebar .logo {
  font-size: 25px;
  margin-bottom: 0;
}

.arrowshow {
  display: none;
}

.arrowHide {}

.sidebar button {
  color: #ffffff;
}

.sidebar .accordion_list li.active {
  background-color: red;
  color: #fff;
}

/* .sidebar li {
  padding: 4% 0% 4% 18%;
  position: relative;
} */

.sidebar li {
  padding: 4% 0% 4% 18%;
  position: relative;
  color: #fff;
  list-style: none;
}

.sidebar li:hover {
  background-color: #00b5fd;
}

/* .accordion {
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: rgb(13 110 253 / 30%);
}*/

/* .sidebar .sidebar_txt {
  color: #fff !important;
  font-weight: 600;
} */

/* .sidebar .sidebar_txt:hover {
  color: #fff !important;
  font-weight: 600;
} */

.sidebar .accordion-item {
  background: #005c97;
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  background: linear-gradient(to right, #005c97, #363795);
  border: 0 !important;
}

.sidebar .accordion-header {
  background-color: #fff;
  border: none;
  border-radius: 3rem 0 0 3rem;
}

.accordion-body {}

.sidebar .accordion-item .collapse .accordion-body {
  /* background-color: #1f4b92; */
  margin: 0px 0px;
  border-radius: 8px;
  transition: 0.2s;
  padding: 0 !important;
}

.fullwidth {
  width: 100%;
}

.lesswidth {
  width: 100px;
}

.sidebar .dropdown-arrow {
  position: absolute;
  right: 5%;
}

.sidebar p {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-size: 15px;
}

.sidebar .sidebar .text-clr {
  color: #99a5b5;
  font-size: 15px;
}

.sidebar .accordion-button {
  background: #005c97;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  /* Chrome 10-25, Safari 5.1-6 */
}

.sidebar .accordion_list {
  margin-bottom: 18px;
}

.sidebar .accordion {
  padding: 12px 0px 0px 1px;
}

.sidebar .accordion-item-sub {
  padding: 12px 0px 0px 20px;
}

.sidebar .child-sub {
  padding: 12px 0px 0px 20px;
}

.sidebar .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #1560c9;
}

.sidebar .top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 5% 5% 5%;
  position: sticky;
  z-index: 9999;
  top: 0px;
  /* background: #0dcaf0; */
  background: #005c97;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #005c97, #363795);
}

.sidebar .top_section .bars {
  margin-right: 10%;
}

.sidebar .accordion-button:hover {
  background: #00b5fd;
  box-shadow: none;
}

.sidebar .accordion-button:not(.collapsed) {
  box-shadow: none;
  border-radius: 3rem 0 0 3rem;
}

.sidebar .accordion-button:focus {
  box-shadow: none;
}

.sidebar .accordion-button::after {
  filter: brightness(0);
}

.sidebar li.active p {
  color: #ffffff;
}

.sidebar .accordion-item.active .text-clr,
.sidebar .accordion-item.active .accordion-header p {
  color: #ffffff;
}

.sidebar .accordion-item:hover .text-clr,
.sidebar .accordion-item:hover .accordion-header p {
  /* color: #ffffff; */
}

.insideDropdown_list {
  color: #ffffff !important;
}

.sidebar li:hover .rounded p {
  color: #ffffff;
}

.sidebar .accordion-button:not(.collapsed) {
  /* background-color: #f8f9fa !important; */
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 13%) !important;
  color: #000 !important;
  transition: 0.4s !important;
  border-radius: 50px 0px 0px 50px !important;
  margin: 10px 0px !important;
  background: rgb(242 242 242);
}

.sidebar .accordion-button:not(.collapsed) b:nth-child(2) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 100%;
  left: 6px;
  background: rgb(242 242 242);
  color: #000 !important;
}

.sidebar .accordion-button:not(.collapsed) b:nth-child(2)::before {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 0px !important;
  width: 98% !important;
  height: 100% !important;
  background: #005c97;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #005c97, #363795);
  border-radius: 0px 0px 50px 0px !important;
}

.sidebar .accordion-button:not(.collapsed) b:nth-child(3) {
  position: absolute;
  top: 52px;
  height: 20px;
  width: 100%;
  left: 6px;
  background: rgb(242 242 242);
  color: #000 !important;
}

.sidebar .accordion-button:not(.collapsed) b:nth-child(3)::before {
  content: "" !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 98% !important;
  height: 100% !important;
  background: #005c97;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #005c97, #363795);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #005c97, #363795);
  border-radius: 0px 50px 0px 0px !important;
}

.sidebar .accordion-button:not(.collapsed),
scale-in-hor-right {
  animation: scale-in-hor-right 0.5s ease-in;
}

.sidebar .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: 0;
}

@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar .accordion {
    padding: 0px 0px 0px 0px;
  }

  .sidebar .logo {
    width: 100%;
    padding: 6px 8px 0px 0px;
  }

  .sidebar p {
    margin-bottom: 0px;
    font-family: Helvetica;
    font-size: 11px;
  }

  .sidebar .accordion-button {
    padding: 9px;
    font-size: 12px;
  }

  .sidebar .accordion-button:not(.collapsed) b:nth-child(3) {
    position: absolute;
    top: 38px;
    height: 20px;
  }

  .sidebar .accordion-button:not(.collapsed) b:nth-child(3)::before {
    left: -2px !important;
  }

  .sidebar .accordion-button:not(.collapsed) b:nth-child(2)::before {
    left: -2px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .sidebar .accordion {
    padding: 12px 0px 0px 6px;
  }

  .sidebar p {
    font-size: 12px;
  }

  .fabars_logo {
    display: none;
  }
}

@media screen and (min-width: 2200px) and (max-width: 2560px) {
  .sidebar .accordion-button:not(.collapsed) b:nth-child(2) {
    left: 9px;
  }

  .sidebar .accordion-button:not(.collapsed) b:nth-child(3) {
    left: 9px;
  }
}